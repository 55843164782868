import React from 'react';

import loadable from '@loadable/component';

import BBBW from 'assets/images/BB-hero-mila-black-and-white.png';
import BBBWWebp from 'assets/images/BB-hero-mila-black-and-white.png?as=webp';
import BB from 'assets/images/BB-hero-mila-white.png';
import BBWebp from 'assets/images/BB-hero-mila-white.png?as=webp';

import { priceTable } from '../static-data';
import { SUPPORTED_REGIONS, CURRENCY_SIGN } from '../../shared/enums';

const ImageLoader = loadable(() => import('./common/ImageLoader'));
const AwardSlider = loadable(() => import('./AwardSlider'));
/**
 * @returns {JSX.Element}
 * @constructor
 */

const contentDataV1 = {
  baseColor: '#FFC140',
  mainHeading: () => (
    <>
      Save up to
      {' '}
      <strong>44%</strong>
    </>
  ),
  title: () => (
    <>
      on the smartest air
      {' '}
      <br />
      {' '}
      purifier of the summer
    </>
  ),
  image: {
    desktop: {
      defaultUrl: BBBW,
      webpUrl: BBBWWebp,
    },
    alt: 'The mother of all filters.',
  },
};
const contentDataV2 = {
  baseColor: '#FFC140',
  mainHeading: () => (
    <>
      Save up to
      {' '}
      <strong>38%</strong>
    </>
  ),
  title: () => (
    <>
      on the smartest air
      {' '}
      <br />
      {' '}
      purifier of the summer
    </>
  ),
  image: {
    desktop: {
      defaultUrl: BB,
      webpUrl: BBWebp,
    },
    alt: 'The mother of all filters.',
  },
};

const regionalDiscount = {
  US: {
    single: '38%',
    multi: '44%',
    regular: '30%',
  },
  CA: {
    single: '38%',
    multi: '44%',
    regular: '29%',
  },
};

const ContentItem = ({
  baseColor, mainHeading, title, image,
}) => (
  <div className='hero hero--june2024 hero--home-center hero--small'>
    <div className='hero__holder'>
      <div className='hero__header'>
        <h1
          className='hero__title hero__title--small'
          style={{ '--color': `${baseColor}` }}
        >
          {mainHeading()}
        </h1>
      </div>
      <ImageLoader
        className='hero__image hero__image--june2024  img-relative'
        placeholderAspectRatio={1376 / 764}
        image={image.desktop.defaultUrl}
        webp={image.desktop.webpUrl}
        alt={image.alt}
      />
      <div className='hero__content'>
        <h2 className='hero__subtitle'>{title()}</h2>
      </div>
    </div>
  </div>
);

const HeroJuly2024 = ({ localization }) => {
  const { region } = localization;
  const regional = SUPPORTED_REGIONS[region] || SUPPORTED_REGIONS.US;
  const regionalBW = [ SUPPORTED_REGIONS.US, SUPPORTED_REGIONS.CA ].includes(SUPPORTED_REGIONS[region]);
  const { price } = priceTable.BB[regional];
  const contentData = regionalBW ? contentDataV1 : contentDataV2;

  return (
    <>
      <section className={`heroFlex ${!regionalBW ? 'heroFlex--alt' : ''}`}>
        <div className='heroFlex__card'>
          <AwardSlider />
          <ContentItem {...contentData} />
        </div>
      </section>

      <div className='price-table price-table--bb'>
        <div className='container'>
          {regionalBW && (
            <>
              <div className='price-table__grid'>
                <div className='price-table__column price-table__column--offer'>
                  <h3 className='price-table__column-title'>Summer Sale ☀️</h3>
                  <div className='price-table__inner-grid'>
                    <div className='hero-price-card price-card--offer'>
                      <div className='price-card__info'>
                        <div className='price-card__info-column'>
                          <strong className='price-card__count  price-card__count--white'>
                            Mila in White
                          </strong>
                          <strong className='price-card__discount'>
                            Save up to
                            {' '}
                            {regionalDiscount[regional].single}
                          </strong>
                        </div>
                        <div className='price-card__info-column'>
                          <strong className='price-card__amount'>
                            {`${CURRENCY_SIGN[regional]}${price.offer.singleUnit}`}
                            {' '}
                            <span>with Auto-Refill</span>
                          </strong>
                          <span className='price-card__old-price'>
                            <s>{`${CURRENCY_SIGN[regional]}${price.range.single.min} to ${CURRENCY_SIGN[regional]}${price.range.single.max}`}</s>
                            {' '}
                            without Auto-Refill
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='hero-price-card price-card--offer'>
                      <div className='price-card__info'>
                        <div className='price-card__info-column'>
                          <strong className='price-card__count price-card__count--black'>
                            Mila in Black
                          </strong>
                          <strong className='price-card__discount'>
                            Save up to
                            {' '}
                            {regionalDiscount[regional].multi}
                          </strong>
                        </div>
                        <div className='price-card__info-column'>
                          <strong className='price-card__amount'>
                            {`${CURRENCY_SIGN[regional]}${price.offer.mutipleUnits}`}
                            {' '}
                            <span>with Auto-Refill</span>
                          </strong>
                          <span className='price-card__old-price'>
                            <s>{`${CURRENCY_SIGN[regional]}${price.range.multi.min} to ${CURRENCY_SIGN[regional]}${price.range.multi.max}`}</s>
                            {' '}
                            without Auto-Refill
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='price-table__column price-table__column--regular'>
                  <h3 className='price-table__column-title'>
                    Everyday Pricing
                  </h3>
                  <div className='hero-price-card price-card--regular'>
                    <div className='price-card__info'>
                      <div className='price-card__info-column'>
                        <strong className='price-card__count'>
                          Mila in White or Black
                        </strong>
                        <strong className='price-card__discount'>
                          Save up to
                          {' '}
                          {regionalDiscount[regional].regular}
                        </strong>
                      </div>
                      <div className='price-card__info-column'>
                        <strong className='price-card__amount'>
                          {`${CURRENCY_SIGN[regional]}${price.regular}`}
                          {' '}
                          <span>with Auto-Refill</span>
                        </strong>
                        <span className='price-card__old-price'>
                          <s>{`${CURRENCY_SIGN[regional]}${price.range.regular.min} to ${CURRENCY_SIGN[regional]}${price.range.regular.max}`}</s>
                          {' '}
                          without Auto-Refill
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='price-table__button'>
                <a
                  href='https://shop.milacares.com'
                  className='btn btn--red btn--medium'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Start Saving
                </a>
              </div>
            </>
          )}
          <div className='price-table__footer'>
            <h3 className='price-table__title'>The Mila Promise</h3>
            <ul className='price-table__list'>
              <li>
                <svg className='icon icon-money-back-guarantee'>
                  <use xlinkHref='#icon-money-back-guarantee' />
                </svg>
                30 day Mila-backed guarantee
              </li>
              <li>
                <svg className='icon icon-shipping'>
                  <use xlinkHref='#icon-shipping' />
                </svg>
                Fast free shipping with same/next business day shipping
              </li>
              <li>
                <svg className='icon icon-warranty'>
                  <use xlinkHref='#icon-warranty' />
                </svg>
                One year warranty
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroJuly2024;
